import { DIRECTION } from "./MainProject/MainProjectSection";
import { Types } from "@/interface/projectsInterface";
import SectionState from "@/model/ModelSectionState";

export type DEVICE_STATUS = "OK" | "WARNING" | "ALERT" | "SUPPORT" | "WAITING" | "INACTIVE";

export interface Reasons {
  label: string;
  id: number;
  detail: string;
}

export default class Section {
  kilometrage: string = "";
  type: SectionType | null = null;
  title: string = "";
  description: string = "";
  id: null | number = null;
  projectId: number = 0;
  ordinalNumber: number = 0;
  mainProjectOrdinalNumber: number = 0;
  boardOne: string = "";
  boardTwo: string = "";
  displayOneType: Types | null = null;
  displayTwoType: Types | null = null;
  state = new SectionState();
  direction: DIRECTION = "DEFAULT";
  override: boolean = false;
  backgroundImage: string = "none.svg";
  fixed?: boolean;
  cbRadioEnabledOne: boolean = false;
  cbRadioEnabledTwo: boolean = false;
  visible: boolean = true;
  radarOnLeftSide: boolean = false;
  deviceOneBatteryNumber: number | null = 1;
  deviceTwoBatteryNumber: number | null = 1;
  deviceOneSolarPanelNumber: number | null = 1;
  deviceTwoSolarPanelNumber: number | null = 1;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }

  public get isMeasure(): boolean {
    if (this.type) {
      return this.type.name === "measure";
    } else {
      return false;
    }
  }

  public get isDisplay(): boolean {
    if (this.type) {
      return this.type.name === "display";
    } else {
      return false;
    }
  }

  public get isCamera(): boolean {
    if (this.type) {
      return this.type.name === "camera";
    } else {
      return false;
    }
  }

  public get isVwa(): boolean {
    if (this.type) {
      return this.type.name === "vwa";
    } else {
      return false;
    }
  }

  public get isDefault(): boolean {
    return this.direction === "DEFAULT";
  }
  formatDisplaySize(name: string) {
    switch (name) {
      case "small":
        return "48x48";
      case "medium":
        return "48x80";
      case "large":
        return "64x96";
      case "vwa":
        return "64x144";
      default:
        return "48x48";
    }
  }

  public get deviceOneSize(): string {
    return this.formatDisplaySize(this.displayOneType?.name || "");
  }
  public get deviceTwoSize(): string {
    return this.formatDisplaySize(this.displayTwoType?.name || "");
  }
}

export interface SectionType {
  id: number;
  name: string;
}

export class NewSectionDto {
  description = "";
  displayOneTypeId = 0;
  displayTwoTypeId = 0;
  kilometrage = 0;
  mainboardOneSerial = "";
  mainboardTwoSerial = "";
  cbRadioEnabledOne = false;
  cbRadioEnabledTwo = false;
  ordinalNumber = 0;
  mainProjectOrdinalNumber = 0;
  projectId = 0;
  projectSectionTypeId = 0;
  title = "";
  backgroundImage: string = "none.svg";
  deviceOneBatteryNumber = 1;
  deviceTwoBatteryNumber = 1;
  deviceOneSolarPanelNumber = 1;
  deviceTwoSolarPanelNumber = 1;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export interface SingleProjectDeviceInDeviceList {
  sectionId: number | null;
  id: string;
  kilometrage: string;
  side: "right" | "left";
  picture: string | null;
  brightness: string;
  position: "deviceOne" | "deviceTwo";
  deviceBatteryState:
    | {
        batteryTerminalCurrent: string;
        batteryTerminalValue: number;
        batteryTerminalUnit: string;
        batteryStatus: string;
        chargingCurrent: string;
        dischargingCurrent: string;
        solarArray: string;
      }
    | undefined;
  status: DEVICE_STATUS | string;
  orderedSection: number;
}
